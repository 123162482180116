




















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as _ from 'lodash';

type TableDataValue =  {
  type: 'input' | 'checkbox' | 'selector';
  value: number | string | boolean;
  disabled?: boolean;
  style?: { [key: string]: string };
  options?: string[];
};

export type TableData = {
  [columnName: string]: TableDataValue;
};
const LEFT_COLUMN_NAME = 'rowKeys';

@Component
export default class NvictaTable extends Vue {
  @Prop({ required: true })
  tableData!: TableData[];

  @Prop({ required: true })
  columnNames!: { [key: string]: string }[]; // table header values

  @Prop({ required: false })
  rowKeys: string[]; // left column values

  @Prop({ required: false, default: '' })
  tableName: string; // left-top corner value

  uidTable: string[] = [];

  @Watch('tableData.length', { immediate: true })
  addUID() {
    while(this.uidTable.length !== this.tableData.length) {
      this.uidTable.push(this.getUid());
    }
  }

  get extendedColumns(): string[] {
    const keys = this.columnNames.map((item: { [key: string]: string }) => Object.keys(item)[0]);
    return [LEFT_COLUMN_NAME, ...keys];
  }

  get readableExtendedColumns(): string[] {
    const keys = this.columnNames.map((item: { [key: string]: string }) => Object.values(item)[0]);
    return [LEFT_COLUMN_NAME, ...keys];
  }

  get leftColumnValues(): string[] {
    return this.rowKeys || _.range(this.tableData.length);
  }

  getUid(): string {
    return Math.random().toString(36).substr(2, 12);
  }

  deleteRow(rowIdx: number) {
    this.uidTable.splice(rowIdx, 1);
    this.$emit('deleteRow', rowIdx);
  }

  getComponent(type: string): string {
    switch(type) {
      case 'text':
        return 'span';
      case 'input-number':
        return 'nvicta-input';
      case 'input-text':
        return 'nvicta-input';
      case 'checkbox':
        return 'nvicta-input';
      default:
        throw new Error(`Unknown type of component: ${type}`);
    }
  }

  getInputType(type: string): string {
    switch(type) {
      case 'text':
        return 'text';
      case 'input-number':
        return 'number';
      case 'input-text':
        return 'text';
      case 'checkbox':
        return 'checkbox';
      default:
        throw new Error(`Unknown type of component: ${type}`);
    }
  }

  getHeaderLabel(field: string): string {
    // TODO: mb it will be formatter or specific TableDataValue format
    if(field === LEFT_COLUMN_NAME) {
      return this.tableName;
    }
    return field;
  }

  getFieldOptions(data: TableData, field: string): string[] | undefined {
    return data[field].options;
  }

  getFieldValue(data: TableData, field: string, idx: number): string | number | boolean {
    if(field === LEFT_COLUMN_NAME) {
      return this.leftColumnValues[idx];
    }
    return data[field].value;
  }

  getFieldStyle(field: string): any {
    return this.tableData[0][field]?.style;
  }

  getFieldType(data: TableData, field: string, idx: number): string {
    if(field === LEFT_COLUMN_NAME) {
      return 'text';
    }
    return data[field].type;
  }

  isFieldDisabled(data: TableData, field: string, idx: number): boolean {
    if(field === LEFT_COLUMN_NAME) {
      return true;
    }
    return data[field].disabled || false;
  }

  onChange(value: number | string | boolean, field: string, rowIdx: number): void {
    this.$emit('onChange', {
      value,
      field,
      rowIdx,
    });
  }
}
