export type Bha = {
  wellId: string;
  name: string;
  startDepth: number;
  endDepth: number;
  active: boolean;
  drillPipeId: number;
  drillPipeOd: number;
  pipeIsFilled: boolean;
  drillPipeUnitWeight: number;
  motor: {
    motorId: string;
    manufacturer: string;
    modelNumber: string;
    rotorOverallLength: number;
    rotorHeadLength: number;
    rotorHeadDiameter: number;
    eccentricity: number;
    rotorWeight: number;
    statorLength: number;
    statorTubeOD: number;
    statorTubeID: number;
    statorWeight: number;
    flowMin: number;
    flowMax: number;
    speedMim: number;
    speedMax: number;
    torqueSlope: number;
    rpg: number;
    maxPressure: number;
    maxTorque: number;
    offBottomPressure: number;
    stallTorque: number;
    stallPressure: number;
    yield: number;
    flowRate: number[];
    rpm: number[][];
    diffPressure: number[][];
    stages: number;
    lobes: string;
  };
  components: BhaComponent[];
  bit: {
    type: string;
    manufacturer: string;
    tfa: number;
    od: number;
    gradeIn: string;
    gradeOut: string;
  };
  startDate: number;
  endDate: number;
  directionalSensorOffset: number;
  pulserSensorOffset: number;
  gammaSensorOffset: number;
  toolfaceCrossover: number;
  directionalSensorSN: string;
  gammaSensorSN: string;
  gammaCorrectionFactor: number;
  drillPipeTjOd: number;
  _id?: string;
};

export type BhaComponent = {
  od: number;
  id: number;
  tjOd: number;
  length: number;
  unitWeight: number;
  type: string;
  isFilled: boolean;
  sequence: number;
};

export type ExternalBhas = {
  DEPTHIN: number[];
  DEPTHOUT: number[];
  BHA_NUM: number[];
  WELL: string[];
  BITTYPE: string[];
  TFA: number[];
  OD: number[];
  ID: number[];
  LENGTH: number[];
  MOTORYIELD: number[];
  MAX_DIFFP: number[];
  MAX_TORQUE: number[];
  MAX_FLOW: number[];
  BIT_NUM: number[];
  REV_PER_GAL: number[];
  BHA_DESC: string[];
  MAKE: string[];
  MODEL: string[];
  WEIGHT: number[];
  DESCRIPTION: string[];
  SEQUENCENO: number[];
  TYPE: number[];
  MAX_RPM: string[];
  GRADEIN: string[];
  GRADEOUT: string[];
  MOTOR_STAGES: string[];
  MOTOR_LOBES: string[];
  STATOR_VENDOR: string[];
  STATOR_TYPE: string[];
  DATEIN: string[];
  DATEOUT: string[];
};

export enum BhaMutation {
  SET_BHAS = 'setBhas',
  SET_EXTERNAL_BHAS = 'setExternalBhas',
  CLEAR_BHAS = 'clearBhas',
  APPEND_BHAS = 'appendBhas',
}

export enum BhaAction {
  FETCH_BHAS = 'fetchBhas',
  UPDATE_BHAS = 'updateBhas',
  ADD_BHA = 'addBha',
  DELETE_BHA = 'deleteBha',
  FETCH_EXTERNAL_BHAS = 'fetchExternalBhas',
}
