export type HydraulicsTimeData = {
  standPipe: number;
  casingShoe: number;
  bottomHole: number;
  time: number;
};

export type HydraulicsDepthDataItem = {
  annularPressure: number;
  depth: number;
};

export type HydraulicsDepthData = {
  time: number;
  data: HydraulicsDepthDataItem[];
};

export enum HydraulicsDataAction {
  FETCH_TIME_DATA = 'fetchHydraulicsTimeData',
  FETCH_DEPTH_DATA = 'fetchHydraulicsDepthData',
}

export enum HydraulicsDataMutation {
  SET_TIME_DATA = 'setHydraulicsTimeData',
  APPEND_TIME_DATA = 'appendHydraulicsTimeData',
  SET_DEPTH_DATA = 'setHydraulicsDepthData',
  SET_TIME_RANGE = 'setHydraulicsTimeRange',
}
