import { HydraulicsTimeData, HydraulicsDepthData, HydraulicsDataMutation } from './types';

import { queryServer } from '@/services/socket_service';

import store from '@/store';

import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import * as _ from 'lodash';

@Module
export class HydraulicsDataModule extends VuexModule {
  _timeData: HydraulicsTimeData[] = [];
  _depthData: HydraulicsDepthData | null = null;
  _timeRange: [number, number] | null = null;

  get hydraulicsTimeData(): HydraulicsTimeData[] {
    return this._timeData;
  }

  get hydraulicsDepthData(): HydraulicsDepthData | null {
    return this._depthData;
  }

  get hydraulicsTimeRange(): [number, number] | null {
    if(_.isEmpty(this._timeData)) {
      return undefined;
    }
    return this._timeRange;
  }

  @Mutation
  setHydraulicsTimeRange(range: [number, number]): void {
    if(_.isEqual(this._timeRange, range)) {
      return;
    }
    this._timeRange = range;
  }

  @Mutation
  setHydraulicsTimeData(data: HydraulicsTimeData[]): void {
    if(_.isEmpty(data) || _.isEqual(this._timeData, data)) {
      return;
    }
    this._timeData = _.cloneDeep(data);
    store.commit(HydraulicsDataMutation.SET_TIME_RANGE, [_.first(data)?.time, _.last(data)?.time]);
  }

  @Mutation
  appendHydraulicsTimeData(data: HydraulicsTimeData[]): void {
    if(_.isEmpty(data)) {
      return;
    }
    if(!this._timeData) {
      this._timeData = _.cloneDeep(data);
    } else {
      this._timeData = _.concat(this._timeData, data);
    }
  }

  @Mutation
  setHydraulicsDepthData(data: HydraulicsDepthData[]): void {
    if(_.isEmpty(data) || _.isEqual(this._depthData, data)) {
      return;
    }
    if(!this._depthData) {
      this._depthData = _.cloneDeep(data);
    } else {
      _.assign(this._depthData, data);
    }
  }

  @Action({ rawError: true })
  async fetchHydraulicsTimeData(payload?: { from?: number, to?: number }): Promise<void> {
    const event = 'hydraulics-time-data/get';

    const params = {
      wellId: this.context.getters.currentWellId,
      from: payload?.from,
      to: payload?.to,
    };

    const resp = await queryServer(event, params);
    if(_.isNil(resp?.data)) {
      return;
    }

    store.commit(HydraulicsDataMutation.SET_TIME_DATA, resp.data);
  }

  @Action({ rawError: true })
  async fetchHydraulicsDepthData(): Promise<void> {
    const event = 'hydraulics-depth-data/get';

    const params = {
      wellId: this.context.getters.currentWellId,
    };

    const resp = await queryServer(event, params);
    if(_.isNil(resp?.data)) {
      return;
    }

    store.commit(HydraulicsDataMutation.SET_DEPTH_DATA, resp.data);
  }
}
