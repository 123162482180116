import { ENVIRONMENT } from './config';
import store from './store';
import { UserType } from './store/modules/users/types';

import * as _ from 'lodash';

export enum RouteName {
  RIGS = 'rigs',
  WELL_DASHBOARD = 'well-dashboard',
  WELLS_PAGE = 'wells-page',
  WELLS_COMPARE = 'wells-compare',
  WELL_SETTINGS = 'well-settings',
  RIG_SETTINGS = 'rig-settings',
  WELL_SUMMARY = 'well-summary',
  DAILY_REPORTS = 'daily-reports',
  USER = 'user',
  USER_SETTINGS = 'user-settings',
  ADMIN = 'admin',
  ADD_WELL = 'add-well',
  WITSML_TEMPLATE = 'witsml-template',
  NOTIFICATION_CENTER = 'notification-center',
  MWD_TOOL_CHECK = 'mwd-tool-check',
  LOGIN = 'login',
  ADD_RIG = 'add-rig',
  MOTORS = 'motors',
  ADD_MOTOR = 'add-motor',
  MOTOR_UPLOAD_GPMS = 'motor-upload-gpms',
  PDF = 'pdf',
  PDF_MULTI_WELLS = 'pdf-multi-wells',
  WELL_NOT_FOUND = 'well-not-found',
  PAGE_404 = '404',
  MOBILE_PAGE = 'mobile',
}

export enum Environment {
  DEV = 'dev',
  PROD = 'prod',
  DEMO = 'demo',
}

export enum Tab {
  // LEFT

  // Drilling Data
  TIME_CHARTS = 'time-charts',
  DEPTH_CHARTS = 'depth-charts',
  COMPARE_WELLS = 'compare-wells',

  // Performance Metrics
  PERFORMANCE_METRICS = 'performance-metrics',
  STATE_TRANSITION_REPORTS = 'state-transition-reports',

  // Directional Guidance
  SURVEYS = 'surveys',
  PSEUDO_SURVEYS = 'pseudo-surveys',
  WELL_PLANS = 'well-plans',
  SLIDE_SHEETS = 'slide-sheets',
  RECOMMENDATIONS = 'recommendations',
  BUILD_TURN_ANALYSIS = 'build-turn-analysis',
  DAILY_REPORTS = 'daily-reports',

  // Torque and Drag
  TORQUE_AND_DRAG = 'torque-and-drag',

  // Hydraulics
  HYDRAULICS = 'hydraulics',

  // RIGHT

  // MWD
  MWD = 'mwd',

  // Directional Plot
  DIRECTIONAL_PLOT = 'directional-plot',

  // Drilling Optimization
  SCATTER = 'scatter',
  FOUNDER = 'founder',
  HEATMAP = 'heatmap',

  // Motor Performance
  MOTOR_CURVE = 'motor-curve',
  STALL_DETECTION = 'stall-detection',
}

function getUserPagesWhiteList() {
  return {
    [UserType.ADMIN]: _.values(RouteName),
    [UserType.ENGINEER]: _.values(RouteName)
      .filter((route: string) => !_.includes(
        // all routes are available except these:
        [
          RouteName.ADMIN,
          RouteName.RIGS,
          RouteName.WELL_SETTINGS,
          RouteName.RIG_SETTINGS,
          RouteName.ADD_WELL,
          RouteName.ADD_RIG,
          RouteName.ADD_MOTOR,
          RouteName.MOTOR_UPLOAD_GPMS,
        ],
        route
      )),
    [UserType.GUEST]: _.concat(
      [RouteName.LOGIN, RouteName.WELL_NOT_FOUND, RouteName.PAGE_404],
      ENVIRONMENT === Environment.DEMO ? [RouteName.WELL_DASHBOARD, RouteName.WELLS_PAGE, RouteName.NOTIFICATION_CENTER, RouteName.MOBILE_PAGE] : []
    ),
  };
}

export const EnvironmentPagesWhiteList = {
  [Environment.PROD]: _.values(RouteName),
  [Environment.DEV]: _.values(RouteName),
  [Environment.DEMO]: _.values(RouteName)
    .filter((route: string) => !_.includes(
      // all routes are available except these:
      [
        RouteName.WELL_SUMMARY,
        RouteName.WITSML_TEMPLATE,
        RouteName.MWD_TOOL_CHECK,
        RouteName.PDF,
      ],
      route
    )),
};

export const EnvironmentTabsWhiteList = {
  [Environment.PROD]: _.values(Tab),
  [Environment.DEV]: _.values(Tab),
  [Environment.DEMO]: _.values(Tab),
};

export function isRouteAllowed(routeName: RouteName): boolean {
  let userType = store.getters.user?.type;
  if(!userType) {
    userType = UserType.GUEST;
  }
  return _.includes(getUserPagesWhiteList()[userType], routeName) && _.includes(EnvironmentPagesWhiteList[ENVIRONMENT], routeName);
}

export function isTabAllowed(tab: Tab): boolean {
  return _.includes(EnvironmentTabsWhiteList[ENVIRONMENT], tab);
}
