

















































































































import { MwdRun, MwdRunAction, MudDataType, MudData, MwdRunId } from '@/store/modules/mwd_run/types';
import { Bha } from '@/store/modules/bha/types';
import { DEFAULT_MWD_RUN } from '@/store/modules/mwd_run/defaults';

import { emitEngineEvent } from '@/services/socket_service';

import { Component, Vue } from 'vue-property-decorator';
import * as _ from 'lodash';

const HIDDEN_FIELDS = ['_id', 'mudData', 'wellId'];
const READONLY_FIELDS = [];
const SELECTOR_FIELDS = ['bhaId', 'type'];

const MWD_RUN_FIELD_MAPPINGS = {
  wellId: 'Well ID',
  name: 'Name',
  operator1: 'Operator 1',
  operator2: 'Operator 2',
  mudData: 'Mud Data',
  bhaId: 'BHA',
  logTop: 'Log Top [ft]',
  logBottom: 'Log Bottom [ft]',
  holeSize: 'Hole Size [in]',
  _id: '_id',
};

const MUD_DATA_FIELD_MAPPINGS = {
  type: 'Type',
  density: 'Density [ppg]',
  funnelViscosity: 'Funnel Viscosity [cP]',
  plasticViscosity: 'Plastic Viscosity [cP]',
  yieldPoint: 'Yield Point [lbf/100ft^2]',
  pH: 'PH',
  chWMud: 'CHW Mud',
  apiFiltrate: 'API Filtrate [mL]',
  maxMwdTemp: 'Max MWD Temperature [deg F]',
};

@Component
export default class MwdRunSettings extends Vue {
  defaultMwdRun = DEFAULT_MWD_RUN;
  expendedMwdRunKey: string | null = null; // key === mwd._id + '-' + idx
  mudDataTypes = MudDataType;
  // TODO: changedIds = [];

  get mwdRunFieldMappings() {
    return MWD_RUN_FIELD_MAPPINGS;
  }

  get mudDataFieldMappings() {
    return MUD_DATA_FIELD_MAPPINGS;
  }

  get mwdRuns(): MwdRun[] {
    return this.$store.getters.mwdRuns;
  }

  get mwdRunKeys(): string[] {
    return _.keys(this.defaultMwdRun).filter((key: string) => !_.includes(HIDDEN_FIELDS, key));
  }

  get mudDataKeys(): string[] {
    return _.keys(this.defaultMwdRun.mudData);
  }

  get bhas(): Bha[] {
    return this.$store.getters.bhas || [];
  }

  getRunName(idx: number, mwdRun: MwdRun) {
    return mwdRun.name ? `MWD Run ${idx}: ${mwdRun.name}` : `MWD Run ${idx}`;
  }

  getItemDetailsStyle(detailsCount: number) {
    return {
      'grid-auto-flow': 'column',
      'grid-template-columns': '1fr 1fr',
      'grid-template-rows': `repeat(${Math.ceil(detailsCount / 2)}, 1fr)`,
    };
  }

  getBhaIdInitValue(mwdRun: MwdRun): Bha {
    return this.bhas.find((bha: Bha) => bha.name === mwdRun.bhaId);
  }

  isSelectorActive(field: string): boolean {
    return _.includes(SELECTOR_FIELDS, field);
  }

  isMudTypeSelectorActive(field: string): boolean {
    return field === 'type';
  }

  isBhaSelectorActive(field: string): boolean {
    return field === 'bhaId';
  }

  isDetailsActive(id: string, idx: number): boolean {
    const key = id + '-' + idx;
    return this.expendedMwdRunKey === key;
  }

  toggleDetails(id: string, idx: number): void {
    if(this.isDetailsActive(id, idx)) {
      this.expendedMwdRunKey = null;
      return;
    }
    this.expendedMwdRunKey = id + '-' + idx;
  }

  isInputDisabled(field: string): boolean {
    return _.includes(READONLY_FIELDS, field);
  }

  getInputType(field: string): string {
    if(_.isString(this.defaultMwdRun[field])) {
      return 'text';
    }
    return 'number';
  }

  onMudTypeSelect(type: MudDataType, mwdRun: MwdRun): void {
    mwdRun.mudData.type = type;
  }

  onBhaSelect(bha: Bha, mwdRun: MwdRun): void {
    mwdRun.bhaId = bha.name;
  }

  deleteMwd(id: string): void {
    this.$store.dispatch(MwdRunAction.DELETE_MWD_RUN, id);
  }

  async onApplyClick(): Promise<void> {
    const resp = await this.$store.dispatch(MwdRunAction.UPDATE_MWD_RUNS);
    if(!resp) {
      return;
    }
    await emitEngineEvent('mwd-runs-updated', {
      wellId: this.$store.getters.currentWellId,
      mwdRuns: this.mwdRuns,
    });
    await this.$store.dispatch('alertSuccess', { title: 'MWD Run settings', message: `Changes applied` });
  }

  async addDefaultMwdRun(): Promise<void> {
    const mwdRun = _.cloneDeep(this.defaultMwdRun);
    mwdRun.wellId = this.$store.getters.currentWellId;
    await this.$store.dispatch(MwdRunAction.CREATE_MWD_RUN, mwdRun);
  }

  async created(): Promise<void> {
    await this.$store.dispatch(MwdRunAction.FETCH_MWD_RUNS);
  }
}
